import React from "react";

import Layout from "app/components/layout";
import SEOMeta from "app/components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEOMeta
      title="404: Page Not Found"
      desc="The page you are looking for could not be found."
    />
    <p>The page you are looking for could not be found.</p>
  </Layout>
);

export default NotFoundPage;
